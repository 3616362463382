@font-face {
  font-family: 'futura_bkbook';
  src: url('./fonts/ftrabk-webfont.woff2') format('woff2'), url('./fonts/ftrabk-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'futura_bkbook';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.33;


}

body #root {
  background: url('./images/page-bg.jpg') no-repeat bottom right !important;
  background-size: 13% auto !important;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  background: #fff;
  color: #57585b;
  font-family: 'futura_bkbook';
}

body button,
.btn,
body a {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  cursor: pointer;
}

input,
button {
  font-family: 'futura_bkbook' !important;
}

.btn:hover,
body button:hover {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  color: #000;
}

body a:hover {
  text-decoration: none;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  color: #000;
}

html {
  scroll-behavior: smooth;
}

::placeholder {
  color: #a8a8a8 !important;
  opacity: 1;
  /* Firefox */
}

.blue {
  color: #314575 !important;
}

.fal {
  font-family: 'Font Awesome 5 Pro';
}

.single-banner {
  position: relative;
}

.App {
  text-align: center;
}

.dark-logo-header {
  height: 59px;
}

.dark-logo-header a:hover {
  color: inherit;
}

.menuLogo {
  width: 120px;
}

.thumb-img {
  width: 100px;
}

.container-service {
  width: 85%;
  padding-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
}

.service-category {
  margin-top: 10px;
}

a {
  text-decoration: none;
}

h2,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: 300;
}

.img-fluid {
  height: auto;
  width: 100vw;
}

h1 {
  font-size: 36px;
  margin: 0;
  padding: 0;
  font-weight: 300;
}

h3 {
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #4a4a4a;
  font-size: 24px;
  line-height: 1.17;
}

h3.page-heading {
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #57585b;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-size: 30px;
}

h2 {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.5px;
  color: #57585b;
}

h4 {
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #4a4a4a;
}

strong {
  font-weight: 600;
}

p {
  margin: 0 0 10px 0;
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.pageContainer {
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

.lineH-2 {
  line-height: 1.6;
}

/*Bootstrap Replica*/
.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.full-width {
  width: 100% !important;
}

.text-center {
  text-align: center !important;
}

.just-cont-between {
  justify-content: space-between !important;
}

.just-cont-center {
  justify-content: center !important;
}

.just-cont-start {
  justify-content: flex-start !important;
}

.align-center {
  align-items: center !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.text-transform {
  text-transform: uppercase;
}

hr.hr-blank {
  height: 1px;
  background: transparent;
  border-bottom: 1px solid #ddd;
  border-right: none;
  border-left: none;
  border-top: none;
  margin: 25px -25px;
}

.p-5 {
  padding: 5px;
}

.p-13 {
  padding: 13px;
}

.p-15 {
  padding: 15px;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-20 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-58 {
  padding-top: 58px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-27 {
  padding-bottom: 27px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-15 {
  padding-right: 15px;
}

.ml-minus-25 {
  margin-left: -25px !important;
}

.mr-minus-25 {
  margin-right: -25px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-27 {
  margin-top: 27px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-27 {
  margin-bottom: 27px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

/* //Bootstrap Replica*/

.brdr-btm {
  border-bottom: 1px solid #d8d8d8;
}

.map-screen {
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.3), inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.light-gray-text {
  color: #a2a3a5 !important;
}

.green-hover:hover {
  color: #ffb6cf !important;
}

.filter-header {
  width: 100%;
  padding-right: 45px;
  text-align: center;
}

.m-minus div input {
  margin-bottom: 10px;
}

.m-minus div:last-child input {
  margin-bottom: 30px;
}

.m-minus .error-minus {
  margin-bottom: 10px;
  margin-top: -10px;
}

.m-minus div:last-child .error-minus {
  margin-bottom: 25px;
  margin-top: -30px;
}

/*Buttons*/
.blue-btn {
  box-shadow: 0 2px 0 0 rgba(87, 88, 91, 0.2) !important;
  border: solid 2px #ffb6cf;
  background-color: #ffffff;
  font-size: 15px !important;
  color: #ffb6cf;
  text-transform: uppercase;
  display: inline-block;
  min-width: 140px;
  font-weight: normal !important;
}

.blue-btn svg {
  font-size: 10px;
  margin-left: 4px !important;
  margin-top: -2px;
}

.small-btn {
  box-shadow: 0 2px 0 0 rgba(87, 88, 91, 0.2) !important;
  border: solid 2px #ffb6cf;
  background-color: #ffffff;
  font-size: 12px !important;
  color: #ffb6cf;
  text-transform: uppercase;
  min-width: 140px;
  font-weight: normal !important;
  font-family: 'Flama Semicondensed Basic' !important;
  padding: 8px 10px;
  display: inline-block;
}

.small-photo-btn {
  box-shadow: 0 2px 0 0 rgba(87, 88, 91, 0.2) !important;
  border: solid 2px #ffb6cf;
  background-color: #ffffff;
  font-size: 12px !important;
  color: #ffb6cf;
  min-width: 140px;
  font-weight: normal !important;
  font-family: 'Flama Semicondensed Basic' !important;
  padding: 8px 10px;
  display: inline-block;
}

.small-btn-a {
  width: 140px !important;
}

.blue-btn:hover,
.small-btn:hover {
  background-color: #ffb6cf;
  color: #ffffff;
}

.no-hover.blue-btn:hover,
.no-hover.small-btn:hover {
  background-color: #ffffff !important;
  color: #ffb6cf !important;
}

.btn-height {
  height: 47px;
}

.small-btn-height {
  height: 33px;
}

.custm-box {
  border: 1px solid #e9e9e9;
  padding: 32px 25px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.form-control,
.multiselect button,
.datetime-trigger input {
  color: #000;
  border: 1px solid #ffccdd !important;
  padding: 0 15px !important;
  font-size: 15px;
  font-weight: 500 !important;
  line-height: 1.33 !important;
  /*font-family: 'acumin-pro', sans-serif !important;*/
  font-family: 'futura_bkbook' !important;
  margin-bottom: 10px !important;
  width: 100% !important;
  display: block;
  background: #ffffff;
  border-radius: 0;
  box-shadow: none;
  height: 46px;
}

.multiselect button {
  display: inline-flex;
}

.multiselect>div>div {
  border-radius: 4px;
  margin-top: -11px !important;
  border: 1px solid #ffccdd !important;
  width: 100%;
  /* top: 100% !important;*/
}

.multiselect-input .rw-widget-container {
  border: 1px solid #ffccdd !important;
}

.css-1jllj6i-control {
  min-width: inherit;
}

textarea.form-control {
  height: 120px;
  padding-top: 15px !important;
}

label {
  margin-bottom: 12px;
  color: #57585b;
  display: flex;
  font-weight: 400;
}

/*//Buttons*/

/*Home Screen*/

.home-header {
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
}

.list-content-img {
  /*margin-right:15px;*/
}

/*// Home Screen*/

.small-para {
  font-size: 10px;
  font-weight: 300;
  line-height: 1.4;
  color: #4a4a4a;
}

.small-para-2 {
  font-size: 11px;
  font-weight: 300;
  line-height: 1.27;
  color: #4a4a4a;
}

.select-search-box {
  position: relative;
  background: #fff;
  border-radius: 4px;
  /*width: 202px;*/
}

.select-search-box--multiple {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.select-search-box::after {
  font-family: 'Font Awesome 5 Pro';
  content: '\f002';
  position: absolute;
  top: 0px;
  right: 20px;
  width: 18px;
  font-size: 10px;
  text-align: center;
  line-height: 23px;
  color: #57585b;
  z-index: 2;
}

.select-search-box__search::placeholder {
  color: #57585b !important;
  opacity: 1;
  /* Firefox */
}

.select-search-box--multiple::after,
.select-search-box--focus::after {
  content: '\f002';
  right: 0;
  left: inherit;
}

.select-search-box__icon--disabled::after {
  content: none !important;
}

.select-search-box--input::after {
  display: none !important;
}

.select-search-box__out {
  display: none;
}

.autocomplete-dropdown-container {
  position: fixed;
  top: 58px;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 9999;
  border-top: 1px solid #d8d8d8;

  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  /*line-height: 0.77;*/
  letter-spacing: normal;
  color: #57585b;

  /*margin-left: -60px;*/
}

.autocomplete-dropdown-container div {
  padding: 8px 15px;
  font-size: 12px;
}

.autocomplete-dropdown-container div:first-child {
  padding-top: 20px;
}

.autocomplete-dropdown-container div:last-child {
  padding-bottom: 20px;
}

.select-search-box__search,
.location-search-input {
  min-height: 24px;
  color: #222f3e;
  -webkit-appearance: none;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  cursor: pointer;
  text-indent: 20px;
  background-color: rgba(233, 233, 233, 0.9);
  border-radius: 5px;
  top: 0;
  border: none;
}

.search-icon:after {
  font-family: 'Font Awesome 5 Pro';
  content: '\f002';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  font-size: 10px;
  text-align: center;
  line-height: 23px;
  color: #57585b;
  z-index: 2;
}

input.select-search-box__search {
  line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
  box-shadow: none;
}

.select-search-box--input .select-search-box__search {
  cursor: text;
}

.select-search-box__search:focus {
  cursor: text;
}

.select-search-box__search--placeholder {
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
  color: #4a4a4a;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.select-search-box input::-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-ms-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.select-search-box__select {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  z-index: 100;
  min-height: 49px;
}

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}

.select-search-box__select--display {
  display: block;
}

.select-search-box__option {
  color: #616b74;
  padding: 15px 20px;
  border-top: 1px solid #eee;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.select-search-box__option:first-child {
  border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #f4f7fa;
}

.select-search-box__option--selected {
  background: #54a0ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #2184ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative;
}

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
}

.select-search-box__options {
  padding-left: 0px;
}

.accordion-heading .accordion__button[aria-expanded='true'] {
  padding-bottom: 15px;
}

.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.accordion__item+.accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 550px) {}
}

.data-render p {
  margin-bottom: 1em;

  text-align: justify;
}

.data-render strong {}

.data-render h2 {
  margin-bottom: 1em;
}

.data-render table tr td {
  padding: 0.5em;
  text-align: left !important;
}

.data-render table p {
  margin-bottom: 0.5em;
}

.data-render h3 {
  margin-bottom: 0.5em;
  font-weight: 300;
  color: #545454;
}

.data-render ul {
  margin: 0;
  padding: 0;
}

.data-render ol {
  margin: 0;
  padding: 0;
}

.data-render li {
  position: relative;
  margin-left: 30px;
  margin-bottom: 8px;
}

.trashicon {
  float: right;
  font-size: 16px;
  color: #314575;
  margin-top: 4px;
  color: #ffb6cf;
}

.react-date-picker__wrapper,
.react-time-picker__wrapper {
  height: 54px;
  border-radius: 0;
  border: 1px solid #a8a8a8 !important;
  margin-bottom: 30px;
  color: #a2a3a5;
  font-weight: 300;
  width: 100%;
}

.react-date-picker,
.react-time-picker {
  width: 100%;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.gm-ui-hover-effect {
  margin: 0px !important;
  top: 10px !important;
  right: 10px !important;
  width: 15px !important;
  height: 15px !important;
  border: 1px solid #ffb6cf !important;
}

.gm-ui-hover-effect img {
  width: 12px !important;
  height: 12px !important;
  margin: 0px !important;
}

.greenText {
  color: #ffb6cf !important;
}

.orgText {
  color: #f57126 !important;
}

.accordion-heading .accordion__button {
  background-color: transparent;
  padding: 25px;
  position: relative;
  outline: none;
}

.accordion-heading:focus,
.accordion-heading:active {
  border: none;
  outline: none;
}

.accordion-heading .accordion__button[aria-expanded='true']::before {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.25;
  color: #ffb6cf;
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  content: '\f068';
  text-align: right;
  height: 100%;
  width: 14px;
  position: absolute;
  right: 25px;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
}

.accordion-heading .accordion__button span,
.accordion-heading .accordion__button span.accident-assistance-step {
  font-weight: normal;
  color: #a2a3a5;
}

.accordion-heading .accordion__button span {
  padding-right: 25px;
}

.faqbox .accordion-heading .accordion__button {
  padding-top: 25px;
}

.faqbox .accordion-heading .accordion__button span {
  font-family: 'acumin-pro', sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  color: #4a4a4a;
}

.faqbox .accordion-heading .accordion__button[aria-expanded='true'] {
  padding-bottom: 10px;
}

.faqbox p {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.38;
  color: #4a4a4a;
}

.faqbox p:last-child {
  margin-bottom: 25px;
}

.accordion-heading .accordion__button span,
.accordion-heading .accordion__button label {
  display: block;
}

.accordion-heading .accordion__button label {
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: semi-condensed;
  color: #77bc1f;
  margin-bottom: 0;
}

.accordion-heading.iconhide .accordion__button[aria-expanded='true']::before {
  display: flex !important;
}

.accordion-heading .accordion__button::before {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.25;
  color: #ffb6cf;
  font-family: 'Font Awesome 5 Pro';
  content: '\f067';
  text-align: right;
  height: 100%;
  width: 14px;
  position: absolute;
  right: 25px;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
}

.accident-accordian .accordion-heading .accordion__button[aria-expanded='true']::before,
.accident-accordian .accordion-heading.iconshow .accordion__button[aria-expanded='false']::before {
  display: none;
}

.accident-accordian .accordion-heading .accordion__button::before {
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  content: '\f078';
  height: 100%;
  width: 14px;
  position: absolute;
  right: 25px;
  top: 0;
  color: #ffb6cf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-panel .accordion-body {
  padding: 0 25px;
}

.accordion-panel .accordion-body .dVYxvh,
.accordion-panel .accordion-body .ovVxc {}

.accordion-panel .accordion-body div,
.accordion-panel .accordion-body p {
  box-sizing: border-box;
}

.accordion-panel.faqbox {
  border-bottom: 1px #e6e5e5 solid;
}

.accordion-panel {
  border-top: 1px #e6e5e5 solid;
  margin-top: 30px;
  margin-left: -25px;
  margin-right: -25px;
}

.accordion-panel .accordion-body .eybVBE {
  margin-right: -25px;
  margin-left: -25px;
  padding: 45px 30px;
}

.accordion-panel .accordion-body .eybVBE+.dVYxvh {
  margin-top: 25px;
}

.accordion-panel .accordion-body .sc-eTuwsz .mb-4 {
  width: 45%;
}

.accordion-panel .accordion-body .eybVBE .jIbBrd {
  font-weight: 300;
  margin-bottom: 25px;
}

.accordion-panel .accordion-body .sc-RefOD {
  margin-bottom: 35px !important;
  margin-top: 0px !important;
}

.accordion-panel .accordion-body .sc-RefOD p {
  margin-bottom: 15px;
}

.hidden {
  display: none;
}

.show {
  display: block !important;
}

.empty-4 {
  margin-top: 12%;
}

/*.headingMargin {
  margin-bottom: 10%;
}*/

.react-date-picker__inputGroup__input,
.react-time-picker__inputGroup__input {
  width: 45px !important;
  text-align: center;
  padding: 0% !important;
}

.filter-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.filter-list li {
  position: relative;
  padding: 12.5px 0;
  border-bottom: 1px solid #ddd;
  font-size: 15px;
  color: #ffb6cf;
}

.filter-text {
  font-weight: 500;
}

.filter-text2 {
  font-weight: 300;
}

.filter-text:after {
  top: 10px;
  right: 5px;
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  color: #ffb6cf;
  position: absolute;
  height: 20px;
  width: 20px;
  font-size: 20px;
  line-height: 1.2;
}

.filter-text2 {
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

/*.react-date-picker__inputGroup { position: relative; }
.react-date-picker__inputGroup:after { position: absolute; width: 100%; height: 100%; content: 'hi';
background: #fff; top: 0; left:0; padding:10px 12px; color:#ffb6cf; }*/
.banner-size {
  /*height: 275px;*/
  width: 100%;
  display: block;
}

.banner-bg {
  background: #fff;
}

.IconWhite {
  color: #fff !important;
}

.svg-inline--fa.fa-w-8 {
  height: 1.3em;
  width: 1.3em;
}

.react-date-picker__inputGroup__input,
.react-time-picker__inputGroup__input {
  color: #ffb6cf;
}

.react-date-picker__calendar {
  width: 100% !important;
}

.react-calendar .react-calendar__tile--active {
  background: #006edc;
  color: #ffb6cf;
}

.react-calendar .react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.5em 0.4em;
  background: none;
}

.react-calendar .react-calendar__navigation {
  height: 36px;
  margin-bottom: 0.5em;
}

.react-date-picker__calendar-button {
  position: relative;
  width: auto;
  height: 100%;
  padding: 0 15px !important;
}

.react-date-picker__calendar-button:after {
  content: '\f073';
  font-family: 'Font Awesome 5 Pro';
  color: #ffb6cf;
  height: 20px;
  width: 20px;
  font-size: 16px;
}

.react-date-picker__button svg {
  display: none !important;
}

.react-time-picker__clear-button {
  position: relative;
  width: auto;
  height: 100%;
  padding: 0 15px !important;
}

.react-time-picker__clear-button:after {
  content: '\f017';
  font-family: 'Font Awesome 5 Pro';
  color: #ffb6cf;
  height: 20px;
  width: 20px;
  font-size: 16px;
}

.uploaded-pics-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.uploaded-pic {
  margin-right: 0.5em;
  width: 30%;
  margin-bottom: 0.5em;
}

.uploaded-pic img {
  max-height: 90px;
  min-height: 90px;
  max-width: 100%;
  min-width: 100%;
}

.service-loc-box {
  margin-bottom: 30px;
}

.service-loc-box img {
  width: 100%;
}

.slb-head {
  margin-bottom: 10px;
}

.slb-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background-color: rgba(233, 233, 233, 0.5);
  border-radius: 100%;
}

.slb-icon svg {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.88;
  text-align: center;
  color: #ffb6cf;
}

.map-footer {
  background: #fff;
  height: 49px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.map-footer p {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.38;
  text-align: center;
  color: #4a4a4a;
  margin: 0;
}

.custom-page {}

last-update {
  font-family: Flama;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;

  line-height: 1.56;
  letter-spacing: normal;
  color: #4a4a4a;
}

.custom-page h2 {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;

  line-height: 1.2;
  letter-spacing: -0.5px;
  color: #57585b;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.custom-page h4 {
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #4a4a4a;
  text-transform: uppercase;
}

.custom-page p {
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 15px;
}

.custom-page td p {
  font-size: 10px;
  line-height: 1.1;
}

.custom-page a {
  color: #ffb6cf;
}

.custom-page ul,
.custom-page ul li {
  position: relative;
  list-style: none;
}

.custom-page ul li::before {
  content: '\2022';
  display: inline-block;
  left: -1.1225em;
  position: absolute;
  top: 0em;
  border-radius: 100%;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.38;
  color: #ffb6cf;
}

.custom-page ol {
  list-style: none;
  counter-reset: lower-latin;
}

.custom-page ol li::before {
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/*Response Start*/

.pos-rel {
  position: relative !important;
}

.trash-icon {
  color: #ffb6cf;
  position: absolute;
  right: 3px;
  top: 2px;
  color: #ffb6cf;
  font-size: 16px;
}

.filter-header-map {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 9;
}

.time-input {
  /* position: absolute; */
}

.map-icon {
  font-size: 8px;
}

input[type='text'],
input[type='email'],
input[type='number'],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.datepicker-height {
  height: 54px;
  width: 100%;
  color: #ffb6cf;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  top: -30px !important;
}

.react-datepicker__input-container {
  position: relative;
}

.react-datepicker__input-container:after {
  content: '\f073';
  font-family: 'Font Awesome 5 Pro';
  color: #ffb6cf;
  height: 20px;
  width: 20px;
  font-size: 16px;
  position: absolute;
  top: 17px;
  right: 10px;
}

.time-icon .react-datepicker__input-container:after {
  content: '\f017';
  right: 11px;
}

.datepicker-height::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffb6cf !important;
}

.datepicker-height::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffb6cf !important;
}

.datepicker-height:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffb6cf !important;
}

.datepicker-height:-moz-placeholder {
  /* Firefox 18- */
  color: #ffb6cf !important;
}

.location-search-input {
  width: 100%;
}

.header-shadow-bg {
  background: url('./images/header-shadow-bg.png') no-repeat center top / 100% auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
}

.zIndex10 {
  z-index: 10;
}

.timePopup.react-datepicker-popper {
  width: 115px;
}

.react-datepicker,
.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100% !important;
}

.active-btn {
  color: rgb(255, 255, 255) !important;
  background: rgb(119, 187, 31) !important;
}

.remove-hover:hover {
  background-color: #ffffff !important;
  color: #ffb6cf !important;
}

.multiselect-input {
  margin-bottom: 10px;
  position: relative;
}

.multiselect-input .rw-widget-container {
  color: #314575;
  border: 1px solid #a8a8a8;
  font-size: 15px;
  font-weight: 500 !important;
  line-height: 1.33 !important;
  color: #314575;

  margin-bottom: 30px;
  display: block;
  background: #ffffff;
  border-radius: 0;
  box-shadow: none;
  min-height: 54px !important;
}

.multiselect-input .rw-multiselect .rw-input-reset,
.multiselect-input .rw-multiselect .rw-input,
.multiselect-input .rw-multiselect .rw-combobox-suggestion,
.multiselect-input .rw-multiselect .rw-dropdown-inner-input,
.multiselect-input .rw-multiselect .rw-dropdown-list-search {
  padding: 15px 20px;
  min-height: 54px;
}

.multiselect-input {
  position: relative;
}

.multiselect-input:before {
  content: '';
  font-family: 'Font Awesome 5 Pro';
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  font-weight: 600;
  text-align: center;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background: url('./images/down-arrow.png') no-repeat;
  background-position: center center;
}

.rw-multiselect-tag-label {
  color: #ffb6cf;
}

.rw-multiselect-tag-btn {
  color: #ffb6cf !important;
}

.multiselect-input .rw-multiselect .rw-select {
  display: none;
}

.multiselect-input .rw-multiselect-taglist {
  min-height: 54px;
}

.gm-bundled-control-on-bottom {
  top: 0px;
  bottom: unset !important;
}

button[disabled] {
  opacity: 0.8 !important;
}

.required-custom {
  color: red;
  margin-bottom: 15px;
  margin-top: -30px;
  display: block;
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.8;
}

/* New css */
.pink-bg {
  position: relative;
}

.pink-bg:after {
  background: url('./images/pink-bg.svg') repeat-x;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 8px;
  top: 50%;
  left: 0;
  content: '';
  z-index: 8;
}

.pink-btn {
  border: none;
  cursor: pointer;
  background-color: rgba(255, 182, 207, 1);
  color: #000000;
  width: 100%;
  min-height: 45px;
  display: block;
  margin-bottom: 15px;
  font: normal normal normal 18px/1.4em futura-lt-w01-book, sans-serif;
  padding: 5px;
}

/*.main-wrapper iframe {   color: #000;
  border: 1px solid #ffccdd !important;
  padding: 0 15px !important;
  font-size: 15px;
  font-weight: 500 !important;
  line-height: 1.33 !important;
  font-family: 'acumin-pro', sans-serif !important;
  margin-bottom: 10px !important;
  width: 100% !important;
  display: block;
  background: #ffffff;
  border-radius: 0;
  box-shadow: none;
  height: 46px;  
}
*/

.StripeElement {
  padding: 15px;
  border: 1px solid #ffccdd;
  background: #fff;
  margin-bottom: 10px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.total-price {
  display: flex;
  justify-content: center;
  padding: 10px 0 15px;
  font-size: 18px;
  color: #000;
}

.total-price strong {
  margin-right: 10px;
}

.date-time-msg {
  font-size: 12px;
  color: #ff0000;
}

.download-link {
  color: #ffb6cf;
  text-decoration: underline;
}

.footer-link {
  display: flex;
  text-align: center;
  padding-bottom: 15px;
}


.terms-condition {}

.terms-condition input[type=checkbox].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.terms-condition input[type=checkbox].css-checkbox+label.css-label {
  padding-left: 30px;
  /*height:20px;*/
  display: inline-block;
  line-height: 1.5;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 5px;
}

.terms-condition input[type=checkbox].css-checkbox:checked+label.css-label {
  /*background-position: 0 -20px;*/
  background-image: url('./images/filled-checkbox.png');

}

.terms-condition label.css-label {
  background-image: url('./images/blank-checkbox.png');
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.terms-condition label.css-label.error-checked {
  background-image: url('./images/error-checkbox.png');
}



.terms-condition a {
  color: #000;
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  z-index: 9;
}

.popup_inner {
  /*margin: 25px auto;*/
  background: #fff;
  /*padding: 20px;*/
  /*width: 75%;*/
  /*height: 90vh;*/
  /*overflow: auto;*/
}

.close-btn {
  position: absolute;
  z-index: 9;
  top: 1px;
  right: 0;
  font-size: 18px;
  cursor: pointer;
}

.popup-content {
  padding-top: 15px;
}

.popup-content p {
  margin-bottom: 15px;
  font-size: 14px;
}

/**********************************Responsive Start*************************************/

@media(max-width: 767px) {
  .footer-link {
    flex-direction: column;
  }
}

@media (min-width: 600px) {
  .main-wrapper {
    width: 75%;
    margin: 0 auto;
  }

  .form-control,
  .datetime-trigger input {
    height: 46px;
  }

}

@media (max-width: 1279px) {
  body #root {
    height: auto;
  }
}

@media (max-width: 767px) {

  html,
  body {
    height: auto;
  }

  body #root {
    background-size: 20% auto !important;
  }
}

@media (max-width: 500px) {
  .StripeElement {
    padding: 10px;
  }

  .pink-btn {
    font-size: 14px;
    min-height: 38px;
  }

  .btn-height {
    height: 38px;
  }

  .form-control,
  .multiselect button,
  .datetime-trigger input {
    padding: 0 10px !important;
    font-size: 14px;
  }

  textarea.form-control {
    padding-top: 10px;
  }

  .close-btn {
    right: 0;
    font-size: 14px;
  }
}

.shortcuts-bar {
  display: none;
}

.red {
  color: #ff0000;
}

.dateSpc {
  color: orange
}

.dateSpc {
  color: #fff;
  background-color: #db524b;
  border: 1px solid #db524b;
  padding: 5px 12px;
  margin-bottom: 10px;
}

.email-exist {
  color: rgb(182, 65, 22);
  margin-bottom: 5px
}

.captcha-message {
  color: red;
}

.value-elm {
  flex-basis: 70%;
  width: 250px;
  padding-right:25px
}

.value-elm ul li {
  list-style: none;  
  color: #000;
  padding: 5px 0 !important;    
  /* white-space: nowrap;
  width: 350px; */
  
}

.value-elm ul li:last-child {
  padding-bottom: 12px;
}

.mn-product {
  display: flex;
  width: 100%;  
  justify-content: space-between;
  align-items: center;
  margin: 0 0 12px 0;
  padding:0;
}

.pr-input {
  
  
  

}
.mn-product .pr-input .form-control {
 margin:0 !important;
}

.pr-input input {
  width: 100%;
  border: solid 1px #eee;
  height: 34px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.pr-input input:focus {
  outline: none;
}


.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



/* ============== responsive start here ============ */


@media (max-width:767px) {
  body {
    font-size: 13px;
  }
  .value-elm ul li {
    list-style: none !important;
    font-size: 12px!important;
    color: #000!important;
    padding: 5px 0!important;    
    white-space: inherit;
  }
  .css-1vr111p-option {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
  .css-1vr111p-option input {
    margin-top: 2px !important;
  }

}